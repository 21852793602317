import { useFormik } from "formik";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Modal, ModalBody, ModalHeader, Progress, Spinner } from "reactstrap";
import * as Yup from "yup";

import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadProps } from "antd";
import PostApi, { uploadFiles } from "api/postApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownArtist from "components/Common/DropdownArtistNew";
import DropdownGroup from "components/Common/DropdownGroup";
import { COLOR_STATUS_FEED, TYPE_ARTIST_OPTION } from "helpers/constans";
import Select from "react-select";
import { getCategoryById } from "store/thunks";
import { mockAuthorId } from "utils";
import IconDeltete from "../../assets/images/icon-delete.png";
import NO_IMAGE from '../../assets/images/no-image-icon.png'
import { Option } from "api/types/_public";
import DropdownArtistNew from "components/Common/DropdownArtistNew";
import DropdownGroupNew from "components/Common/DropdownGroupNew";

export default function CreateEditWallpaper({
  setIsOpen,
  dispatch,
  query,
  item,
  setItemToEdit,
  loadTableData,
  STATUS_FEED_OPTION_LANG,
  listArtist,
  listGroup,
  setLinkImage
}: any) {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState<boolean>(false);

  const isEdit = item;

  const props: UploadProps = {
    name: "files",
    accept: '.png,.jpg,.webp,.jpeg',
    beforeUpload: () => false,
    headers: {
      authorization: "authorization-text",
    },
  };

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        type: "card",
        artistIds: !!values?.artistId ? [values?.artistId] : [],
        categoryIds: !!values?.categoryId ? [values?.categoryId] : [],
        status: values?.status?.value || "activated",
        tagIds: [],
        files: values?.files,
        authorId: mockAuthorId,
      };

      const response: any = isEdit
        ? await PostApi?.updatePost(item?._id, data)
        : await PostApi?.createPost(data);
      if (response?.data) {
        //  dispatch(onGetPosts(query));
        loadTableData();
        setIsLoading((_prev) => false);
        setIsOpen(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading(false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading(false);
      toast(`Please re-check your data`);
      return error;
    }
  };

  const validationSchema = Yup.object({
    files: Yup.array().min(1, `${t("This field is required")}`).required(`${t("This field is required")}`)
  });

  const formik = useFormik({
    initialValues: {
      artistId: null,
      categoryId: null,
      files: [],
      status: STATUS_FEED_OPTION_LANG[0],
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const vStatus =
      STATUS_FEED_OPTION_LANG?.filter(
        (i: any) => String(i?.value) == String(item?.status)
      )[0] || STATUS_FEED_OPTION_LANG[1];

    formik.setFieldValue("status", vStatus);

    if (item) {
      const formatedFile = item?.files?.map((i: any) => {
        return {
          name: "image.png",
          url: i?.url,
          thumbUrl: i?.url,
          ...i,
        };
      });
      formik.setFieldValue("files", formatedFile);
      formik.setFieldValue("artistId", item?.artists?.[0]?._id);
      formik.setFieldValue("categoryId", item?.categories?.[0]?._id);

      // setTimeout(() => {
      //   formik.setFieldValue("categoryId", { value: `${item?.categoryIds?.[0]}`, label: '' });
      // }, 300);
    }
  }, [item]);

  const onCloseClick = () => {
    setIsOpen(false);
    setItemToEdit(false);
  };

  const handleChangeArtist = (e: any) => {
    formik.setFieldValue("artistId", e?.value);
    if(item) {
      formik.setFieldValue("categoryId", item?.categories?.[0]?._id);
    }else {
      formik.setFieldValue("categoryId", null);
    }

    if (e?.value && listArtist) {
      const artist = listArtist.find((i: any) => i?.value == e?.value);

      if (artist?.data?.categories?.length > 0) {
        formik.setFieldValue("categoryId", artist?.data?.categories?.[0]?._id);
      }
    }
  }

  return (
    <Modal isOpen={true} centered={true} scrollable={false} size="lg">
      <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>
        {isEdit ? t("Update Wallpaper") : t("Create Wallpaper")}
      </ModalHeader>
      <ModalBody className="py-3 px-4">
        <div className="mb-4">
          <Progress value={100 - (100 / 2) * Number(Object.keys(formik.errors).length)} color="primary" className="animated-progress progress-sm" />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="row g-3">
            <Col xxl={12}>
              <label className="form-label">
                {t("Artist")}
              </label>
              <DropdownArtistNew
                name="artist"
                dataList={listArtist || []}
                placeholder={`${t("Select Artist")}...`}
                className="dropdown-status-rounded"
                classNamePrefix="name-prefix"
                initialValue={formik?.values?.artistId || null}
                onChangeSelect={(e: any) => handleChangeArtist(e)}
                isHasOptionAll={false}
                isClearable={true}
              />
              {formik.touched.artistId && formik.errors.artistId ? (
                <div className="text-danger mt-2">
                  {formik.errors.artistId}
                </div>
              ) : null}
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Group")}
              </label>
              <DropdownGroupNew
                name="categoryId"
                dataList={listGroup || []}
                placeholder={`${t("Select Group")}...`}
                className="dropdown-status-rounded"
                classNamePrefix="name-prefix"
                initialValue={formik?.values?.categoryId || null}
                onChangeSelect={(e: any) => formik.setFieldValue("categoryId", e?.value)}
                isHasOptionAll={false}
                isClearable={true}
              />
            </Col>

            <Col lg={12}>
              <label className="form-label">
                {t("Status")}
                <span className="text-danger"> *</span>
              </label>

              <Select
                name="status"
                className="mb-0 dropdown-status-rounded"
                classNamePrefix="name-prefix"
                placeholder={t("Status")}
                value={formik?.values?.status || STATUS_FEED_OPTION_LANG[1]}
                onChange={(event: any) =>
                  formik.setFieldValue("status", event)
                }
                options={STATUS_FEED_OPTION_LANG}
                formatOptionLabel={(option: any) => (
                  <div className="d-flex align-items-center">
                    <span className={`bg-${option?.value !== '' ? COLOR_STATUS_FEED?.[option?.value] : 'secondary'}`} style={{ width: "15px", height: '15px', borderRadius: '3px' }}></span>
                    <div className="ms-2">
                      {option?.label}
                    </div>
                  </div>
                )}
              ></Select>
              {formik?.touched?.status && formik?.errors?.status ? (
                <div className="text-danger mt-2">
                </div>
              ) : null}
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Photo")}
                <span className="text-danger"> *</span>
              </label>

              {formik.getFieldProps("files")?.value && (
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  {formik.getFieldProps("files")?.value?.map((i: any, index: number) => {
                    if (index == 0) {
                      if (i?.type == "video")
                        return (
                          <div style={{ position: "relative" }}>
                            <video
                              width="160"
                              height="120"
                              controls
                              key={i?.url}
                              id="lesson-video"
                            >
                              <source src={i?.url} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                            <span
                              onClick={() => formik.setFieldValue("files", "")}
                              style={{ color: "red", position: "absolute", top: "-14px", right: "-8px", cursor: "pointer" }}>
                              <i className="ri-delete-bin-3-fill align-bottom"></i>
                            </span>
                          </div>
                        );
                      else
                        return (
                          <div style={{ position: "relative" }}
                          >
                            <img
                              alt="wallpaper"
                              src={i?.url}
                              style={{ maxHeight: 120, maxWidth: 160 }}
                              onClick={() => {
                                setLinkImage(i?.url);
                              }}
                            />

                            <span
                              onClick={() => formik.setFieldValue("files", "")}
                              style={{ color: "red", position: "absolute", top: "-14px", right: "-8px", cursor: "pointer" }}>
                              <img src={IconDeltete} alt="delete" width={18} height={18} />
                            </span>
                          </div>
                        );
                    }
                  })}
                </div>
              )}

              <div>
                {(isLoadingUploadImage) && (
                  <Spinner size="sm" className="me-2" color="#4da3da" style={{ color: "#4da3da" }}></Spinner>
                )
                }
              </div>
              <div>
                <Upload
                  {...props}
                  multiple={false}
                  beforeUpload={() => false}
                  //   listType="picture-card"
                  // fileList={(formik.getFieldProps("files")?.value as any) || []}
                  fileList={[]}
                  onChange={async (info: any) => {
                    setIsLoadingUploadImage(true)
                    try {
                      const cloneInfo = info?.fileList?.length
                        ? [...info?.fileList]
                        : [];

                      const files = info.fileList;
                      const data = new FormData();
                      for (var i = 0; i < files?.length; i++) {
                        data.append("files", files[i]?.originFileObj);
                      }

                      const res = (await uploadFiles(data)) as any;
                      if (res?.success) {
                        setIsLoadingUploadImage(false)
                        const fortmatedData = res?.data?.map(
                          (i: any, index: number) => {
                            return {
                              type: cloneInfo[index]?.type.includes("video")
                                ? "video"
                                : "image",
                              url: i?.url,
                              preview: i?.url,
                              elements: [],
                              caption: "Bonus Image",
                              mimetype: cloneInfo[index]?.type.includes("video")
                                ? "video/*"
                                : "image/*",
                              width: 1080,
                              height: 1080,
                              ratio: 1.1,
                              seq: 0,
                              // ...i,
                            };
                          }
                        );
                        formik.setFieldValue("files", fortmatedData);
                      } else {
                        setIsLoadingUploadImage(false)
                      }
                    } catch (error: any) {
                      setIsLoadingUploadImage(false)
                      toast(`${t('Input buffer contains unsupported image format')}`, CONFIG_OPTION_TOAST_ERROR);
                    }
                  }}
                  onRemove={() => {
                    //return onGalleryFileRemove();
                  }}
                >
                  <Button icon={<UploadOutlined />}>
                    {t("Click to Upload")}
                  </Button>
                </Upload>
                {formik?.touched?.files && formik?.errors?.files ? (
                  <div className="text-danger mt-2">
                    {formik?.errors?.files}
                  </div>
                ) : null}
              </div>
            </Col>

            <div className="col-lg-12">
              <div className="hstack gap-2 justify-content-end">
                <button
                  className="btn btn-primary fs-14"
                  color="success"
                  type="submit"
                  disabled={isLoading}
                  style={{ width: '120px' }}
                >
                  {isLoading ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) :
                    <>{isEdit ? t("Button Update") : t("Button Create")}</>
                  }
                </button>
                <button
                  className="btn btn-secondary fs-14"
                  color="light"
                  type="button"
                  onClick={onCloseClick}
                  disabled={isLoading}
                  style={{ width: '100px' }}
                >
                  {t("Button Close")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
