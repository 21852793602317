import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownOption from "components/Common/DropdownOption";
import DropdownPlatform from "components/Common/DropdownPlatform";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalPreview from "components/Common/ModalPreview";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import {
  getPlatFormImage,
  LIST_OPTION_OS,
  LIST_OPTION_PLATFORM,
  TYPE_ARTIST_OPTION,
} from "helpers/constans";
import { formatDateStringToKorea } from "helpers/format";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import { createSelector } from "reselect";
import { getWallpapersDownload } from "store/emotion/thunk";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { capitalizeFirstLetter } from "utils";
import avatar1 from "../../assets/images/users/ic_defaul_avatar2.png";
import countryApi from "api/countryApi";
import countries from "helpers/countries";
import DropdownCountries from "components/Common/DropdownCountries";
import CollapseFilter from "components/Common/CollapseFilter";
import DropdownArtistNew from "components/Common/DropdownArtistNew";
import DropdownGroupNew from "components/Common/DropdownGroupNew";
import PostApi from "api/postApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import artistApi from "api/artistApi";
import { getOptionAllCategories } from "store/thunks";
import NO_IMAGE from "../../assets/images/no-image-icon.png";

interface Option {
  label: string;
  value: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";

registerLocale("en", en);
registerLocale("ko", ko);

const WallpaperDownloadList = (activeWallpaperTab?: any) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [postChangeArtistEdit, setPostChangeArtistEdit] = useState<any>(null);
  const [postChangeGroupEdit, setPostChangeGroupEdit] = useState<any>(null);
  const [postIdEdit, setpostIdEdit] = useState<any>(null);
  const [artistOnTable, setArtistOnTable] = useState<any>(null);
  const [groupOnTable, setGroupOnTable] = useState<any>(null);
  const [editingCell, setEditingCell] = useState(null);
  const [editingGroupCell, setEditingGroupCell] = useState(null);

  const [artistSearch, setArtistSearch] = useState<any | null>(null);
  const [groupSearch, setGroupSearch] = useState<any | null>(null);

  const [listArtist, setListArtist] = useState<any>([]);
  const [listGroup, setListGroup] = useState<any>([]);

  const selectAristRef = useRef<any>([]);

  const LIST_OPTION_OS_LANG =
    LIST_OPTION_OS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const TYPE_ARTIST_OPTION_LANG =
    TYPE_ARTIST_OPTION?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [queryDownload, setQueryDownload]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    "sort[createdAt]": withDefault(StringParam, `desc`),
    nickname: withDefault(StringParam, ""),
    email: withDefault(StringParam, ""),
    ipAddress: withDefault(StringParam, ""),
    deviceOS: withDefault(StringParam, ""),
    flatform: withDefault(StringParam, ""),
    country: withDefault(StringParam, ""),
    startDate: withDefault(StringParam, ""),
    endDate: withDefault(StringParam, ""),
    artistId: withDefault(StringParam, ""),
    categoryId: withDefault(StringParam, ""),
  });

  const [linkImageDownload, setLinkImageDownload] = useState<string>("");
  const [ipAddressSearch, setIPAddressSearch] = useState<string>(
    queryDownload?.ipAddress || ""
  );
  const [nicknameSearch, setNicknameSearch] = useState<string>(
    queryDownload?.nickname || ""
  );
  const [emailSearch, setEmailSearch] = useState<string>(
    queryDownload?.email || ""
  );
  const [countrySearch, setCountrySearch] = useState<any | null>(null);
  const [oSSearch, setOSSearch] = useState<Option | null>(
    LIST_OPTION_OS_LANG?.filter(
      (item) => String(item?.value) === String(queryDownload?.deviceOS)
    )[0]
  );
  const [platFormSearch, setPlatFormSearch] = useState<Option | null>(
    LIST_OPTION_PLATFORM?.filter(
      (item) => String(item?.value) === String(queryDownload?.platform)
    )[0]
  );

  const [dateSearch, setDateSearch] = useState<any[]>([
    queryDownload?.startDate
      ? moment(queryDownload?.startDate || "", "Y-MM-DD").toDate()
      : null,
    queryDownload?.endDate
      ? moment(queryDownload?.endDate || "", "Y-MM-DD").toDate()
      : null,
  ]);
  const [startDate, endDate] = dateSearch;

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.WallpaperDownload;

  const PostProperties = createSelector(selectLayoutState, (state) => ({
    wallpaperDownloads: state.wallpaperDownloads,
    isWallpaperDownloadLoading: state.isWallpaperDownloadLoading,
    isWallpaperDownloadSuccess: state.isWallpaperDownloadSuccess,

    error: state.error,
  }));

  // Inside your component
  const { wallpaperDownloads, isWallpaperDownloadLoading } =
    useSelector(PostProperties);
  const [listCountry, setListCountry] = useState<any>([]);

  function loadTableData() {
    dispatch(getWallpapersDownload(queryDownload));
  }

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(queryDownload)]);

  const searchData = () => {
    setQueryDownload({
      ...queryDownload,
      nickname: nicknameSearch || "",
      email: emailSearch || "",
      ipAddress: ipAddressSearch || "",
      deviceOS: oSSearch?.value || "",
      flatform: platFormSearch?.value || "",
      country: countrySearch?.value || "",
      startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : "",
      endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
      artistId: artistSearch || "",
      categoryId: groupSearch || "",
    });
  };

  const resetDataDownload = () => {
    setQueryDownload(
      {
        nickname: "",
        email: "",
        ipAddress: "",
        deviceOS: "",
        flatform: "",
        country: "",
        startDate: "",
        endDate: "",
        sort_by: TYPE_SELECT_DEFAULT,
        time_request: +new Date(),
        page: 1,
        artistId: "",
        categoryId: "",
      },
      "push"
    );
    setNicknameSearch((_prev) => "");
    setEmailSearch((_prev) => "");
    setIPAddressSearch((_prev) => "");
    setPlatFormSearch((_prev) => null);
    setOSSearch((_prev: any) => null);
    setDateSearch([null, null]);
    setCountrySearch((_prev: any) => "");
    setArtistSearch((_prev: any) => null);
    setGroupSearch((_prev: any) => null);
  };

  const handleUpdateArtistGroup = async (type = "artist", data?: any) => {
    const postID = postChangeArtistEdit ?? postChangeGroupEdit;
    if (!data || !postID) return;

    try {
      const response: any = await PostApi?.updatePost(postID, data);

      if (response?.data) {
        loadTableData();
        setIsOpen(false);

        if (type == "group") {
          setPostChangeGroupEdit(null);
        } else {
          setPostChangeArtistEdit(null);
        }

        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        if (type == "group") {
          setPostChangeGroupEdit(null);
        } else {
          setPostChangeArtistEdit(null);
        }
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      if (type == "group") {
        setPostChangeGroupEdit(null);
      } else {
        setPostChangeArtistEdit(null);
      }
      toast(`Please re-check your data`);
      return error;
    }
  };

  const handleChangeArtistTable = (e: any) => {
    selectAristRef?.current[e?.value]?.focus();
    setArtistOnTable(e?.value);
    const artist = listArtist.find((i: any) => i?.value == e?.value);
    let data = null;

    if (artist?.data?.categories.length > 0) {
      setEditingGroupCell(null);
      data = {
        artistIds: !!e?.value ? [e?.value] : [],
        categoryIds: !!artist?.data?.categories?.[0]?._id
          ? [artist?.data?.categories?.[0]?._id]
          : [],
      };
    } else {
      data = {
        artistIds: !!e?.value ? [e?.value] : [],
      };
    }

    handleUpdateArtistGroup("artist", data);
  };

  const handleChangeGroupTable = (e: any) => {
    setGroupOnTable(e?.value);
    const data = {
      categoryIds: e?.value ? [e?.value] : [],
    };
    handleUpdateArtistGroup("group", data);
  };

  const handleCellClick = (cellId: any) => {
    setEditingCell(cellId);
  };
  const handleCellGroupClick = (cellId: any) => {
    setEditingGroupCell(cellId);
  };

  const handleClickArtistOutside = (e: any) => {
    if (editingCell && !e.target.closest(`.editable-cell-${editingCell}`)) {
      setArtistOnTable(null);
      setPostChangeArtistEdit(null);
    }
  };

  const handleClickGroupOutside = (e: any) => {
    if (
      editingGroupCell &&
      !e.target.closest(`.editable-group-cell-${editingGroupCell}`)
    ) {
      setGroupOnTable(null);
      setPostChangeGroupEdit(null);
    }
  };

  // Column
  const columnsDownload = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        Cell: ({ ...props }) => {
          return (
            <div>
              {wallpaperDownloads?.total -
                ((queryDownload?.page - 1) * queryDownload?.limit +
                  props?.row?.index)}
            </div>
          );
        },
      },
      {
        Header: t("Original Photo"),
        accessor: "parent",
        thClass: "text-center",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => {
          const imgOriginal = cell?.value?.files?.[0]?.url;

          return imgOriginal ? (
            <>
              <div className="w-100 text-center " style={{ minWidth: "150px" }}>
                <div
                  style={{ width: "90px" }}
                  className="m-auto cursor-pointer"
                  onClick={() => setLinkImageDownload((prev) => imgOriginal)}
                >
                  <img
                    src={imgOriginal}
                    className="flex-shrink-0 rounded w-100"
                    alt="thumbnail"
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          );
        },
      },
      {
        Header: t("Download Photo"),
        accessor: "files",
        thClass: "text-center",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => {
          return cell?.value?.[0]?.url ? (
            <>
              <div className="w-100 text-center " style={{ minWidth: "150px" }}>
                <div
                  style={{ width: "90px" }}
                  className="m-auto cursor-pointer"
                  onClick={() => setLinkImageDownload((prev) => cell?.value?.[0]?.url)}
                >
                  <img
                    src={cell?.value?.[0]?.url}
                    className="flex-shrink-0 rounded w-100"
                    alt="thumbnail"
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          );
        },
      },
      {
        Header: t("Artist"),
        accessor: "artist",
        filterable: true,
        sortable: false,
        thWidth: 370,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const artist = item?.artists?.[0];
          return (
            <>
              <div
                tabIndex={item?._id}
                onClick={() => handleCellClick(item?._id)}
                className={`editable-cell-${item?._id} text-center d-flex justify-content-center align-items-center`}
                style={{ minWidth: "140px", width: "100%" }}
              >
                {postChangeArtistEdit === item?._id ? (
                  <div
                    className="d-flex"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <DropdownArtistNew
                      inputRefToFocus={(el: any) =>
                        (selectAristRef.current[item?._id] = el)
                      }
                      name="artistIds"
                      dataList={listArtist || []}
                      placeholder={`${t("Select Artist")}...`}
                      className="dropdown-status-rounded flex-grow-1"
                      classNamePrefix="name-prefix"
                      initialValue={artistOnTable || null}
                      onChangeSelect={(e: any) => handleChangeArtistTable(e)}
                      isHasOptionAll={false}
                      isClearable={true}
                      optionAll={{ label: t("All Filter"), value: "" }}
                    />
                  </div>
                ) : artist ? (
                  <>
                    <div className="flex-grow-1">
                      {artist?.name?.ko} <br />
                      {artist?.name?.en} <br />
                      {TYPE_ARTIST_OPTION_LANG?.find(
                        (item: any) =>
                          String(item?.value) === String(artist?.type)
                      )?.label || ""}
                      <br />
                      {t(`${artist?.gender || ""}`)}
                    </div>
                  </>
                ) : (
                  <div className="flex-grow-1">-</div>
                )}

                <div
                  className={`ms-1 cursor-pointer ${postChangeArtistEdit === item?._id ? "d-none" : "d-block"
                    }`}
                  onClick={() => {
                    setPostChangeArtistEdit(item?._id);
                    setArtistOnTable(artist ? artist?._id : null);
                    setpostIdEdit(item?._id);
                  }}
                >
                  <i
                    className="ri-pencil-fill text-primary"
                    style={{ fontSize: "16px" }}
                  ></i>
                </div>

                <div
                  className={`ms-1 cursor-pointer ${postChangeArtistEdit == item?._id ? "d-block" : "d-none"
                    }`}
                  onClick={() => setPostChangeArtistEdit(false)}
                >
                  <i
                    className="ri-close-circle-fill text-black"
                    style={{ fontSize: "16px" }}
                  ></i>
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: t("Group"),
        accessor: "categories",
        filterable: true,
        sortable: false,
        thWidth: 320,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const category = cell?.value?.length > 0 ? cell?.value?.[0] : "";

          return (
            <>
              <div
                tabIndex={item?._id}
                onClick={() => handleCellGroupClick(item?._id)}
                className={`editable-group-cell-${item?._id} text-center d-flex justify-content-center align-items-center`}
                style={{ minWidth: "140px", width: "100%" }}
              >
                {postChangeGroupEdit == item?._id ? (
                  <DropdownGroupNew
                    name="categoryIds"
                    dataList={listGroup || []}
                    placeholder={`${t("Select Category")}...`}
                    className="dropdown-status-rounded flex-grow-1"
                    classNamePrefix="name-prefix"
                    initialValue={groupOnTable || null}
                    onChangeSelect={(e: any) => handleChangeGroupTable(e)}
                    isHasOptionAll={false}
                    isClearable={true}
                    optionAll={{ label: t("All Filter"), value: "" }}
                  />
                ) : category ? (
                  <>
                    <div className="flex-grow-1">
                      {category?.title?.ko} <br />
                      {category?.title?.en}
                    </div>
                  </>
                ) : (
                  <div className="flex-grow-1">-</div>
                )}

                <div
                  className={`ms-1 cursor-pointer ${postChangeGroupEdit == item?._id ? "d-none" : "d-block"
                    }`}
                  onClick={() => {
                    setPostChangeGroupEdit(item?._id);
                    setGroupOnTable(category ? category?._id : null);
                    setpostIdEdit(item?._id);
                  }}
                >
                  <i
                    className="ri-pencil-fill text-primary"
                    style={{ fontSize: "16px" }}
                  ></i>
                </div>

                <div
                  className={`ms-1 cursor-pointer ${postChangeGroupEdit == item?._id ? "d-block" : "d-none"
                    }`}
                  onClick={() => setPostChangeGroupEdit(false)}
                >
                  <i
                    className="ri-close-circle-fill text-black"
                    style={{ fontSize: "16px" }}
                  ></i>
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: t("Nickname"),
        accessor: "author_nickname",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: "text-center whitespace-nowrap",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="cursor-pointer text-center">
                {item?.author?.nickname}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Email"),
        accessor: "email",
        filterable: true,
        sortable: false,
        thWidth: 180,
        thClass: "text-center whitespace-nowrap",
        Cell: (cell: any) => {
          const item = cell?.row?.original;

          return (
            <>
              <div className="cursor-pointer text-center">
                {item?.author?.email}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Platform"),
        accessor: "providers_platform",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        thWidth: 120,
        Cell: (cell: any) => {
          let platform = cell?.row?.original?.author?.providers || [];

          if (platform.length > 1) {
            platform = platform.filter((item: any) => item?.type !== "default");
          }

          return (
            platform?.length > 0 && (
              <div className="d-flex flex-column gap-1">
                {platform?.map((item: any, index: number) => {
                  const type = item?.type;
                  let imgPlatform = getPlatFormImage(type);
                  return (
                    <div
                      key={index}
                      className="cursor-pointer d-flex gap-2 align-items-center whitespace-nowrap"
                    >
                      <div style={{ width: "25px" }}>
                        {imgPlatform && (
                          <img
                            src={getPlatFormImage(type)}
                            width={25}
                            height={25}
                            alt="platform"
                          />
                        )}
                      </div>
                      <div className="cursor-pointer">
                        {t(
                          capitalizeFirstLetter(
                            type === "default" ? "Email" : type
                          )
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          );
        },
      },
      {
        Header: t("Device Model"),
        accessor: "deviceModel",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: "text-center whitespace-nowrap",
        Cell: (cell: any) => {
          const deviceModel = cell?.row?.original?.author?.config?.deviceModel;
          return (
            deviceModel && (
              <div className="cursor-pointer text-center">
                {t(`${deviceModel}`)}
              </div>
            )
          );
        },
      },
      {
        Header: t("OS"),
        accessor: "deviceOS",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: "text-center",
        Cell: (cell: any) => {
          const deviceOS = cell?.row?.original?.author?.config?.deviceOS;
          return (
            deviceOS && (
              <div className="cursor-pointer text-center" style={{ width: 80 }}>
                {t(`${deviceOS}`)}
              </div>
            )
          );
        },
      },
      {
        Header: t("IP"),
        accessor: "author_ip",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-center whitespace-nowrap",
        Cell: (cell: any) => {
          const ipAddress = cell?.row?.original?.author?.config?.ipAddress;
          return (
            ipAddress && (
              <div className="cursor-pointer text-center">{ipAddress}</div>
            )
          );
        },
      },
      {
        Header: t("Country"),
        accessor: "country",
        thClass: "text-center whitespace-nowrap",
        filterable: true,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => {
          const items = cell?.row?.original?.author?.countryDetail || {};
          const country = countries.find((item) => item?.code === items?.name);

          return (
            country?.code && (
              <div
                className="d-flex flex-column gap-1"
                style={{ minWidth: "100px" }}
              >
                {
                  <div className="cursor-pointer d-flex gap-2 align-items-center ">
                    {country && (
                      <>
                        <img
                          src={country?.flags}
                          width={20}
                          height={20}
                          alt="profile"
                        />
                        <div className="cursor-pointer">
                          {i18n?.language == "ko"
                            ? country?.name_korea
                            : country?.name}
                        </div>
                      </>
                    )}
                  </div>
                }
              </div>
            )
          );
        },
      },
      {
        Header: t("Location"),
        accessor: "location",
        filterable: true,
        sortable: false,
        thClass: "text-center whitespace-nowrap",
        thWidth: 130,
        Cell: (cell: any) => {
          const lat = cell?.value?.latitude;
          const lng = cell?.value?.longitude;

          return (
            (lat || lng) && (
              <div className="cursor-pointer text-center">
                <a
                  href={`https://www.google.com/maps/search/${lat},${lng}/@${lat},${lng},17z?entry=ttu`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Location
                </a>
              </div>
            )
          );
        },
      },
      {
        Header: t("Download Time"),
        accessor: "createdAt",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value);
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ minWidth: "120px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
    ],
    [i18n?.language, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQueryDownload({ page: page + 1 });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQueryDownload((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  // Begin::Delete

  const onCloseImageDownloadClick = () => {
    setLinkImageDownload((_prev) => "");
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        const [resCountry]: any = await Promise.all([
          countryApi.countries({ page: 1, limit: 20000 }),
        ]);
        setListCountry((_prev: any) => resCountry?.data || []);
      } catch (error: any) {
        return error;
      }

      try {
        const [resArtist, resGroup]: any = await Promise.all([
          artistApi.artists({ page: 1, limit: 20000 }),
          getOptionAllCategories({ limit: 2000 }),
        ]);
        // setListArtist((_prev: any) => resArtist?.data?.items || []);
        // setListGroup((_prev: any) => resGroup?.data?.items || []);

        setListArtist((_prev: any) => {
          if (resArtist?.data?.items) {
            return resArtist?.data?.items?.map((a: any) => ({
              value: String(a?._id),
              label: (
                <div className="d-flex align-items-center gap-2">
                  <div
                    style={{
                      height: "22px",
                      width: "25px",
                      overflow: "hidden",
                      borderRadius: "15px",
                      objectFit: "cover",
                      backgroundColor: "#eaeaea",
                      zIndex: 9,
                      minWidth: "22px",
                      minHeight: "22px",
                    }}
                  >
                    <img
                      src={a?.avatar || NO_IMAGE}
                      alt={a?.name}
                      height={22}
                      width={22}
                      className="me-2"
                      style={{
                        overflow: "hidden",
                        borderRadius: "50%",
                        objectFit: "cover",
                        backgroundColor: "#eaeaea",
                      }}
                    />
                  </div>
                  {`${a?.name?.ko} - ${a?.name?.en} (${TYPE_ARTIST_OPTION_LANG?.find(
                    (item: any) => String(item?.value) === String(a?.type)
                  )?.label || ""
                    } - ${t(a?.gender)})`}
                </div>
              ),
              data: a,
            })) as Option[];
          } else {
            return [];
          }
        });

        setListGroup((_prev: any) => {
          if (resGroup?.data?.items) {
            return resGroup?.data?.items?.map((a: any) => ({
              value: String(a?._id),
              label: (
                <div className="d-flex align-items-center gap-2">
                  <div
                    style={{
                      height: "22px",
                      width: "22px",
                      overflow: "hidden",
                      borderRadius: "15px",
                      objectFit: "cover",
                      backgroundColor: "#EAEAEA",
                      minWidth: "22px",
                      minHeight: "22px",
                    }}
                  >
                    <img
                      src={a?.image || NO_IMAGE}
                      alt={a?.title?.ko}
                      height={"100%"}
                      width={22}
                      className="me-2"
                      style={{
                        overflow: "hidden",
                        borderRadius: "50%",
                        objectFit: "cover",
                        backgroundColor: "#EAEAEA",
                      }}
                    />
                  </div>
                  {`${a?.title?.ko} - ${a?.title?.en}`}
                </div>
              ),
              data: a,
            })) as Option[];
          } else {
            return [];
          }
        });
      } catch (error: any) {
        return error;
      }
    };

    handleCallAllOption();
  }, []);

  return (
    <div>
      <CardHeader className="border-0">
        <Row className="g-4 align-items-center">
          <div className="col-sm">
            <div>
              <h5 className="card-title mb-0">
                <span className="me-2">{t("Total")}:</span>
                <CountUp
                  start={0}
                  end={wallpaperDownloads?.total || 0}
                  duration={1}
                  className="text-primary"
                />
              </h5>
            </div>
          </div>
          {/* <div className="col-sm-auto">
              <button
                type="button"
                className="btn btn-success d-none d-md-block"
                onClick={() => {
                  setIsOpen(true)
                  setItemToEdit(null)
                }}
              >
                <i className="ri-add-fill align-bottom me-1"></i>{" "}
                {t("Create Wallpaper Download")}
              </button>
            </div> */}
        </Row>
      </CardHeader>
      <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
        <CollapseFilter>
          <Row className="g-4 align-items-center mt-0 mt-md-2">
            <Col sm={12} md={4} xl={3} xxl={3} className="mt-3 mt-md-3">
              <LabelWrapper label={t("OS")} isShow={true}>
                <DropdownOption
                  name="deviceOS"
                  dataList={LIST_OPTION_OS_LANG || []}
                  placeholder={`${t("OS")}...`}
                  className="dropdown-status-rounded"
                  classNamePrefix="name-prefix"
                  initialValue={oSSearch || null}
                  onChangeSelect={(e: any) => setOSSearch(e)}
                  isHasOptionAll={true}
                  optionAll={{ label: t("All Filter"), value: "" }}
                />
              </LabelWrapper>
            </Col>

            <Col sm={12} md={4} xl={3} xxl={3} className="mt-3 mt-md-3">
              <LabelWrapper label={t("Platform")} isShow={true}>
                <DropdownPlatform
                  name="platform"
                  placeholder={`${t("Platform")}...`}
                  className="dropdown-status-rounded"
                  classNamePrefix="name-prefix"
                  initialValue={platFormSearch || null}
                  onChangeSelect={(e: any) => setPlatFormSearch(e)}
                  isHasOptionAll={true}
                  optionAll={{ label: t("All Filter"), value: "" }}
                />
              </LabelWrapper>
            </Col>

            <Col
              sm={12}
              md={4}
              xl={3}
              xxl={3}
              className="mt-3 mt-md-3 date-picker-wrapper-custom"
            >
              <LabelWrapper label={t("Date")} isShow={!!startDate || !!endDate}>
                <DatePickerCustom
                  startDate={startDate || null}
                  endDate={endDate || null}
                  onChangePicker={handleChangePicker}
                />
              </LabelWrapper>
            </Col>
            <Col sm={12} md={4} xl={3} xxl={3} className="d-none d-xl-block mt-3 mt-md-3" />
            <Col
              sm={12}
              md={4}
              xl={3}
              xxl={3}
              className="mt-3 mt-md-3 date-picker-wrapper-custom"
            >
              <LabelWrapper label={t("Country")} isShow={true}>
                <DropdownCountries
                  name="country"
                  dataList={listCountry || []}
                  placeholder={`${t("Select Country")}...`}
                  className="dropdown-status-rounded"
                  classNamePrefix="name-prefix"
                  initialValue={countrySearch || null}
                  onChangeSelect={(e: any) => setCountrySearch(e)}
                  isHasOptionAll={true}
                  optionAll={{ label: t("All Filter"), value: "" }}
                />
              </LabelWrapper>
            </Col>

            <Col sm={12} md={4} xl={3} xxl={3} className="mt-3 mt-md-3">
              <LabelWrapper label={t("Artist")} isShow={true}>
                <DropdownArtistNew
                  name="artist"
                  dataList={listArtist || []}
                  placeholder={`${t("Select Artist")}...`}
                  className="dropdown-status-rounded"
                  classNamePrefix="name-prefix"
                  initialValue={artistSearch || null}
                  onChangeSelect={(e: any) => setArtistSearch(e?.value)}
                  isHasOptionAll={true}
                  optionAll={{ label: t("All Filter"), value: "" }}
                />
              </LabelWrapper>
            </Col>

            <Col sm={12} md={4} xl={3} xxl={3} className="mt-3 mt-md-3">
              <LabelWrapper label={t("Group")} isShow={true}>
                <DropdownGroupNew
                  name="categoryId"
                  dataList={listGroup || []}
                  placeholder={`${t("Select Group")}...`}
                  className="dropdown-status-rounded"
                  classNamePrefix="name-prefix"
                  initialValue={groupSearch || null}
                  onChangeSelect={(e: any) => setGroupSearch(e?.value)}
                  isHasOptionAll={true}
                  optionAll={{ label: t("All Filter"), value: "" }}
                />
              </LabelWrapper>
            </Col>
            <Col sm={12} md={4} xl={3} xxl={3} className="d-none d-xl-block mt-3 mt-md-3" />
            <Col sm={12} md={4} xl={3} xxl={3} className="mt-3 mt-md-3">
              <LabelWrapper label={t("Nickname")} isShow={!!nicknameSearch}>
                <Input
                  type="text"
                  className="form-control search"
                  placeholder={`${t("Nickname")}...`}
                  value={nicknameSearch}
                  onChange={(e) => setNicknameSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchData();
                    }
                  }}
                />
              </LabelWrapper>
            </Col>

            <Col sm={12} md={4} xl={3} xxl={3} className="mt-3 mt-md-3">
              <LabelWrapper label={t("Email")} isShow={!!emailSearch}>
                <Input
                  type="text"
                  className="form-control search"
                  placeholder={`${t("Email")}...`}
                  value={emailSearch}
                  onChange={(e) => setEmailSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchData();
                    }
                  }}
                />
              </LabelWrapper>
            </Col>

            <Col sm={12} md={4} xl={3} xxl={3} className="mt-3 mt-md-3">
              <LabelWrapper label={t("IP Address")} isShow={!!ipAddressSearch}>
                <Input
                  type="text"
                  className="form-control search"
                  placeholder={`${t("IP Address")}...`}
                  value={ipAddressSearch}
                  onChange={(e) => setIPAddressSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchData();
                    }
                  }}
                />
              </LabelWrapper>
            </Col>

            <Col
              sm={12}
              md={12}
              xl={3}
              xxl={3}
              className="hstack gap-1 justify-content-center justify-content-xl-start mt-sm-4 mt-md-3"
            >
              <div>
                <button
                  type="button"
                  className="btn btn-primary me-1"
                  onClick={searchData}
                  disabled={isWallpaperDownloadLoading}
                >
                  <i className="ri-search-line align-bottom me-1"></i>{" "}
                  {t("Button Search")}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary fs-14"
                  onClick={resetDataDownload}
                >
                  <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                  {t("Button Reset")}
                </button>
              </div>
            </Col>
          </Row>
        </CollapseFilter>
      </div>
      <CardBody className="p-0">
        <div>
          <TableContainer
            idTableName="wallpaper-download"
            className="custom-header-css"
            divClass="table-responsive"
            tableClass="align-middle table-bordered-dashed sticky-table-wallpaper-download"
            theadClass="table-light text-muted"
            columns={columnsDownload}
            data={
              wallpaperDownloads?.items?.length ? wallpaperDownloads?.items : []
            }
            customPageSize={queryDownload.limit}
            customPageIndex={queryDownload.page - 1}
            totalRecords={wallpaperDownloads?.total}
            customPageCount={Math.ceil(
              Number(wallpaperDownloads?.total) / Number(queryDownload.limit)
            )}
            handleChangePage={handleChangePage}
            manualSorting={true}
            sorting={{
              sort_by: queryDownload.sort_by,
              order_by: queryDownload.order_by,
            }}
            handleChangeSorting={handleChangeSorting}
            isLoading={isWallpaperDownloadLoading}
            isShowHeaderTableFixedTab={activeWallpaperTab}
          />
        </div>
        <ToastContainer closeButton={false} limit={1} />
      </CardBody>

      <ModalPreview
        type={"IMAGE"}
        isOpen={!!linkImageDownload}
        url={linkImageDownload}
        onClose={() => onCloseImageDownloadClick()}
      />
    </div>
  );
};

export default WallpaperDownloadList;
