import {
  CONFIG_OPTION_TOAST_ERROR
} from "common/toast";import i18n from "i18n";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate';
import Select, { components } from "react-select";
import userApi from "api/userApi";
import { getPlatFormImage } from "helpers/constans";
import { toast } from "react-toastify";

interface Option {
  label: string | any;
  value: string;
}
interface DropdownOptionProps {
  name?: string;
  listCheckedUsers?: string | null;
  debounceTimeout?: number;
  onChangeSelect?: (params: Option) => void;
  isMulti?: boolean;
  placeholder?: string;
  isShowIconCheck?: boolean;
  hideSelectedOptions?: boolean;
}
const LIMIT = 30

const DropdownAsyncUsers = ({
  name = "",
  listCheckedUsers,
  debounceTimeout = 700,
  onChangeSelect,
  isMulti = false,
  placeholder,
  isShowIconCheck = false,
  hideSelectedOptions = true,
}: DropdownOptionProps) => {
  const { t } = useTranslation();
  const [isAllCheckedUsers, setIsAllCheckedUsers] = useState(false)

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  }

  const loadListUsers = async ({
    page,
    limit,
    search
  }: {
    page: number
    limit: number
    search?: string
  }) => {
    const { data: { items, lastPage }, success } = await userApi.users({ page, limit, keyword: search });
    if (!success) {
      return { success: false };
    }
    const dataUsersOptions = items?.map((a: any) => {
      let platforms = a?.providers || [];
      if (platforms.length > 1) {
        platforms = platforms.filter((item: any) => item?.type !== "default");
      }

      let type = platforms?.[0]?.type;
      let imgPlatform = type ? getPlatFormImage(type) : '';

      return ({
        value: String(a?._id),
        label: (
          <div className="d-flex align-items-center gap-2">
            {imgPlatform && 
              <div style={{ width: "20px" }}>
                {imgPlatform && (
                  <img
                    src={imgPlatform}
                    width={20}
                    height={20}
                    alt="platform"
                  />
                )}
              </div>
            }
            <div>
              {`${a?.nickname ? a?.nickname + " -" : ''} ${a?.email}`}
            </div>
          </div>
        ),
        data: a,
      })
    })
    return {
      success,
      dataUsersOptions,
      lastPage
    };
  }

  async function loadOptions(search: string, loadedOptions: any[], { page }: { page: number }) {
    const {
      success,
      dataUsersOptions,
      lastPage
    } = await loadListUsers({ page, limit: LIMIT, search });
    if (!success) {
      toast(`${t("Failed to load data")}`, CONFIG_OPTION_TOAST_ERROR);
      return {
        options: [],
        hasMore: false,
      };
    }
    return {
      options: [...(page === 1 ? [{ label: t("All Filter"), value: "all" }]: []), ...dataUsersOptions],
      hasMore: lastPage > page,
      additional: {
        page: page + 1,
      },
    };
  }

  const MenuList = (props: any) => {
    let childComponent = props?.children;
    if(props?.children?.length > 0) {
      const allOption = props?.children?.filter((item: any) => item?.props?.value == 'all');
      let fixedIndex = -1;
      if(allOption) {
        fixedIndex = props?.children.indexOf(allOption?.[0]);
      }
      const fixedElement = props?.children?.[fixedIndex];

      const childComponentFormat = (props?.children.slice(1) || [])?.sort((a: any, b: any) => {
        let pointA = a.props.isSelected === true ? 1 : 0;
        let pointB = b.props.isSelected === true ? 1 : 0;
        return pointB - pointA;
      })

      if(fixedElement) {
        childComponent = [fixedElement, ...childComponentFormat]
      }else {
        childComponent = childComponentFormat;
      }
    }
    
    return (
      <components.MenuList {...props}>{childComponent}</components.MenuList>
    );
  }

  const Option = (props: any) => {
    if (props?.isSelected && props?.value == "all") {
      setIsAllCheckedUsers(true);
    } else if (!props?.isSelected && props?.value == "all") {
      setIsAllCheckedUsers(false);
    }

    return (
      <components.Option {...props} style={{ zIndex: 999 }}>
        <div className="d-flex align-items-center gap-2">
          {((props?.isSelected || isAllCheckedUsers) && isShowIconCheck) ? (
            <i className="ri-check-line align-middle fs-14" />
          ) : (
            ""
          )}
          {props.data?.label}
        </div>
      </components.Option>
    );
  }

  const MenuListAsync = wrapMenuList(MenuList);

  return (
    <AsyncPaginate
      isMulti={isMulti}
      isClearable
      defaultOptions
      debounceTimeout={debounceTimeout}
      value={listCheckedUsers}
      loadOptions={loadOptions}
      placeholder={placeholder}
      className="dropdown-status-rounded"
      hideSelectedOptions={false}
      onChange={(e: any) => setOnChangeOption(e)}
      additional={{
        page: 1,
      }}
      components={{
        Option: Option,
        MenuList: MenuListAsync,
      }}
      closeMenuOnSelect={hideSelectedOptions}
      menuPortalTarget={document.body}
      styles={{
        option: (styles: any) => {
          let styleNew = isMulti ? {
            ...styles,
            backgroundColor: "white",
            zIndex: 99,
            color: "black",
          } : styles;

          return styleNew;
        },
        control: (styles: any, { isFocused }: any) => {
          return {
            ...styles,
            borderColor: "#e9ebec",
            borderRadius: "0.6rem",
            color: "white",
            "&:hover": { borderColor: "#009ef7" },
          };
        },
        multiValue: (styles: any) => {
          return {
            ...styles,
            backgroundColor: "#009ef7",
          };
        },
        multiValueLabel: (styles: any) => ({
          ...styles,
          color: "white !important",
        }),
        menuPortal: (base: any) => ({
          ...base,
          zIndex: 99,
        }),
      }}
    />
  );
};

export default DropdownAsyncUsers;
