import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import Keywords from "components/Common/Keywords";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Col,
  Container,
  Input,
  Label,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
//import images
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadProps } from "antd";
import { uploadFiles } from "api/postApi";
import SearchFilterCategory from "components/Common/SearchFilterCategory";
import { useRole } from "components/Hooks/UserHooks";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { getArtist, postArtist, putArtist } from "store/artist/thunk";
import IconDeltete from "../../assets/images/icon-delete.png";

export interface Tag {
  id: string;
  text: string;
}

export interface Props {
  isModal?: boolean;
  id?: string;
  isCopy?: boolean;
  triggerRefresh?: () => void;
  listCategory: [];
  LIST_OPTION_TYPE: any;
  setLinkImage?: any;
  isCloseModal?: any;
  isGroupMultiple?: any;
  createArtistAndAutoAssignGroup?: any;
  rowEditId?: any;
  rowSelected?: any;
}

registerLocale("en", en);
registerLocale("ko", ko);

const LIST_OPTION_STATUS = [
  {
    label: "Status_Activated",
    value: "activated",
  },
  {
    label: "Status_Disabled",
    value: "disabled",
  },
];

const LIST_OPTION_GENDER = [
  {
    label: "Gender_Unknown",
    value: "unknown",
  },
  {
    label: "Gender_Male",
    value: "male",
  },
  {
    label: "Gender_Female",
    value: "female",
  },
];

const ArtistForm = ({
  isModal = false,
  id = "",
  isCopy = false,
  triggerRefresh,
  listCategory,
  LIST_OPTION_TYPE,
  setLinkImage,
  isCloseModal,
  isGroupMultiple = true,
  createArtistAndAutoAssignGroup,
  rowEditId,
  rowSelected,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const [isSetDefaultDone, setIsSetDefaultDone] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
  const [isLoadingUploadImage, setIsLoadingUploadImage] =
    useState<boolean>(false);

  const [initialValuesDefault, setInitialValuesDefault] = useState<any>(null);
  const [listCheckedCategory, setListCheckedCategory] = useState([]);

  const LIST_OPTION_TYPE_LANG = LIST_OPTION_TYPE?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const LIST_OPTION_STATUS_LANG = LIST_OPTION_STATUS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const LIST_OPTION_GENDER_LANG = LIST_OPTION_GENDER?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const props: UploadProps = {
    name: "files",
    beforeUpload: () => false,
    headers: {
      authorization: "authorization-text",
    },
  };

  const onChangeSelectCategory = (event: any) => {
    setListCheckedCategory(event);
    let categoryIds = [];

    if (isGroupMultiple === true) {
      categoryIds = event.map((item: any) => item?.value);
    } else {
      categoryIds = [event?.value];
    }

    formik.setFieldValue("categoryIds", categoryIds);
  };

  const handleSubmit = async (values: any) => {
    try {
      isCloseModal && isCloseModal();

      setIsLoading((_prev) => true);
      const data = {
        name: {
          ko: values?.name.ko,
          en: values?.name.en,
        },
        gender: values?.gender,
        type: values?.type?.value,
        status: values?.status?.value,
        avatar: values?.avatar,
        categoryIds: !!values?.categoryIds ? values?.categoryIds : [],
        keywords: values?.keywords,
      };

      const response: any = id
        ? await putArtist(id, data)
        : await postArtist(data);
      if (response?.data) {
        createArtistAndAutoAssignGroup && createArtistAndAutoAssignGroup(response?.data, rowEditId);
        setIsLoading((_prev) => false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        triggerRefresh && triggerRefresh();
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    name: Yup.object().shape({
      en: Yup.string().required(`${t("This field is required")}`),
      ko: Yup.string().required(`${t("This field is required")}`),
    }),
    type: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required(`${t("This field is required")}`),
    gender: Yup.string().required(`${t("This field is required")}`),
    status: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required(`${t("This field is required")}`),
    avatar: Yup.string().nullable(),
    categoryIds: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      name: {
        ko: "",
        en: "",
      },
      gender: "unknown",
      type: LIST_OPTION_TYPE_LANG[0],
      status: LIST_OPTION_STATUS_LANG[0],
      avatar: "",
      categoryIds: [],
      keywords: [],
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const initialValueTypes =
    (formik?.values?.keywords?.map((item: any) => ({
      id: item?.toLowerCase(),
      text: item,
    })) as any) || [];

  const handleSetValueForm = (valueDefault: any) => {
    const vNameKo = valueDefault?.name.ko || "";
    const vNameEn = valueDefault?.name.en || "";
    const vGender = valueDefault?.gender || "unknown";
    const vType =
      LIST_OPTION_TYPE_LANG?.filter(
        (item: any) => String(valueDefault?.type) == String(item?.value)
      )[0] || LIST_OPTION_TYPE_LANG[0];
    const vStatus =
      LIST_OPTION_STATUS_LANG?.filter(
        (item: any) => String(valueDefault?.status) == String(item?.value)
      )[0] || LIST_OPTION_STATUS_LANG[0];
    const vAvatar = valueDefault?.avatar || "";
    const vCategoryIds = valueDefault?.categoryIds || "";
    const vKeywords = valueDefault?.keywords || [];

    formik.setFieldValue("gender", vGender);
    formik.setFieldValue("type", vType);
    formik.setFieldValue("status", vStatus);
    formik.setFieldValue("avatar", vAvatar);
    formik.setFieldValue("categoryIds", vCategoryIds);
    formik.setFieldValue("keywords", vKeywords);

    if (valueDefault?.name) {
      setTimeout(() => {
        formik.setFieldValue("name", { en: vNameEn, ko: vNameKo });
        // formik.setFieldValue("name.ko", vNameKo);
      }, 300);
    }
  };

  const handleCallAllOption = async (idItem: string) => {
    try {
      // if (!idItem) {
      //   return;
      // }
      setIsLoadingDetail((_prev) => true);
      const [resDetail]: any = await Promise.all([
        idItem ? getArtist(idItem) : {},
      ]);
      setInitialValuesDefault((_prev: any) => resDetail?.data || {});
      handleSetValueForm(resDetail?.data);
      setIsLoadingDetail((_prev) => false);
    } catch (error: any) {
      setIsLoadingDetail((_prev) => false);
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    document.title = `${t("Button Create Artist")} - ${t("Artist")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    if (initialValuesDefault && initialValuesDefault?.categoryIds?.length > 0) {
      const categoryId = initialValuesDefault?.categoryIds;
      const lstCategorySelected = listCategory.filter(
        (item: any) => categoryId.includes(item?._id) ?? item
      );
      const result = lstCategorySelected?.map((item: any) => ({
        label: item?.title?.en + " - " + item?.title?.ko,
        value: String(item?._id || ""),
      })) as any;

      setListCheckedCategory(result);
    }
  }, [initialValuesDefault]);

  useEffect(() => {
    if (isSetDefaultDone) return;
    if (
      !formik?.getFieldProps("avatar")?.value &&
      rowSelected?.files?.[0]?.url
    ) {
      rowSelected?.files?.[0]?.url &&
        formik.setFieldValue("avatar", rowSelected?.files?.[0]?.url);
      setIsSetDefaultDone(true);
    }
  }, [rowSelected, formik]);

  return (
    <React.Fragment>
      {isLoadingDetail && (
        <div
          style={{
            position: "absolute",
            zIndex: 3,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgb(164 164 164 / 36%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="sm" color="primary"></Spinner>
        </div>
      )}
      <div>
        <Container fluid className="px-0">
          <div className="mb-4">
            <Progress
              value={
                100 - (100 / 2) * Number(Object.keys(formik.errors).length)
              }
              color="primary"
              className="animated-progress progress-sm"
            />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="titleInput" className="form-label">
                    {t("Name")} <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="name.en"
                    type="text"
                    className="form-control"
                    id="titleInput"
                    placeholder={t("Enter your title")}
                    value={formik?.values?.name?.en}
                    onChange={(event: any) =>
                      formik.setFieldValue("name.en", event?.target?.value)
                    }
                  />
                  {formik?.touched?.name?.en && formik?.errors?.name?.en ? (
                    <div className="text-danger mt-2">
                      {formik?.errors?.name?.en}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="titleKoreaInput" className="form-label">
                    {t("Name Korea")} <span className="text-danger"> *</span>
                  </Label>
                  <Input
                    name="name.ko"
                    type="text"
                    className="form-control"
                    id="titleKoreaInput"
                    placeholder={t("Enter your title (korea)")}
                    value={formik?.values?.name?.ko}
                    onChange={(event: any) =>
                      formik.setFieldValue(`name.ko`, event?.target?.value)
                    }
                  />
                  {formik?.touched?.name?.ko && formik?.errors?.name?.ko ? (
                    <div className="text-danger mt-2">
                      {formik?.errors?.name?.ko}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="titleKoreaInput" className="form-label">
                    {t("Gender")} <span className="text-danger"> *</span>
                  </Label>

                  <div className="d-flex justify-content-start align-items-center">
                    {LIST_OPTION_GENDER_LANG.map((item) => {
                      return (
                        <div
                          className="d-flex justify-content-start align-items-center form-check me-3"
                          key={item.value}
                          onClick={() => {
                            formik.setFieldValue("gender", item.value);
                          }}
                        >
                          <input
                            className="form-check-input me-2"
                            type="radio"
                            name="gender"
                            value={item.value}
                            checked={formik?.values?.gender === item?.value}
                            style={{ minWidth: "16px" }}
                            onChange={(event) => {
                              formik.setFieldValue(
                                "gender",
                                event?.target?.value
                              );
                            }}
                          />
                          <label
                            className="form-check-label position-relative"
                            htmlFor={`gender`}
                          >
                            {item.label}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="typeInput" className="form-label">
                    {t("Type")} <span className="text-danger"> *</span>
                  </Label>

                  <Select
                    name="type"
                    className="mb-0 dropdown-status-rounded"
                    classNamePrefix="name-prefix"
                    placeholder={t("Type")}
                    value={formik?.values?.type || LIST_OPTION_TYPE_LANG[0]}
                    onChange={(event: any) =>
                      formik.setFieldValue("type", event)
                    }
                    options={LIST_OPTION_TYPE_LANG}
                  ></Select>
                  {/* {formik?.touched?.type && formik?.errors?.type ? (
                    <div className="text-danger mt-2">{formik?.errors?.type}</div>
                  ) : null} */}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="statusInput" className="form-label">
                    {t("Status")} <span className="text-danger"> *</span>
                  </Label>
                  <Select
                    name="type"
                    className="mb-0 dropdown-status-rounded"
                    classNamePrefix="name-prefix"
                    placeholder={t("Status")}
                    value={formik?.values?.status || LIST_OPTION_STATUS_LANG[0]}
                    onChange={(event: any) =>
                      formik.setFieldValue("status", event)
                    }
                    options={LIST_OPTION_STATUS_LANG}
                  ></Select>
                  {formik?.touched?.status && formik?.errors?.status ? (
                    <div className="text-danger mt-2">
                      {/* {formik?.errors?.status} */}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <label className="form-label">{t("Groups")}</label>
                  <SearchFilterCategory
                    name={`categoryIds`}
                    id={`categoryIds`}
                    className="dropdown-status-rounded"
                    classNamePrefix="name-prefix"
                    dataList={listCategory}
                    isMulti={isGroupMultiple}
                    initialValue={listCheckedCategory}
                    onChangeSelect={(event: any) => {
                      onChangeSelectCategory(event);
                    }}
                  />
                  {(formik.touched?.categoryIds as any) &&
                  (formik.errors?.categoryIds as any)?.media ? (
                    <div className="text-danger mt-2">
                      {(formik.errors?.categoryIds as any)?.media}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <label className="form-label">{t("Keywords")}</label>

                  <Keywords
                    name="keywords"
                    placeholder={`${t("Keywords")}...`}
                    suggestions={[]}
                    initialValue={initialValueTypes || []}
                    onChangeTag={(event) => {
                      const keywords = event?.map((item) => item.text);
                      formik.setFieldValue("keywords", keywords);
                    }}
                  />
                  {formik.touched.keywords && formik.errors.keywords ? (
                    <div className="text-danger mt-2">
                      {formik.errors.keywords}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={12} className="mt-3">
                <div className="mb-3">
                  <Label htmlFor="avatarInput" className="form-label">
                    {t("Avatar")}
                  </Label>

                  <div>
                    {formik.getFieldProps("avatar")?.value && (
                      <div
                        style={{
                          display: "flex",
                          gap: 10,
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <img
                            alt="avatar"
                            src={formik.getFieldProps("avatar")?.value}
                            style={{ maxHeight: 120, maxWidth: 160 }}
                            onClick={() =>
                              setLinkImage(
                                formik.getFieldProps("avatar")?.value
                              )
                            }
                          />
                          <span
                            onClick={() => formik.setFieldValue("avatar", "")}
                            style={{
                              color: "red",
                              position: "absolute",
                              top: "-14px",
                              right: "-8px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={IconDeltete}
                              alt="delete"
                              width={18}
                              height={18}
                            />
                          </span>
                        </div>
                      </div>
                    )}
                    <div>
                      {isLoadingUploadImage && (
                        <Spinner
                          size="sm"
                          className="me-2"
                          color="#4da3da"
                          style={{ color: "#4da3da" }}
                        ></Spinner>
                      )}
                    </div>

                    <Upload
                      {...props}
                      multiple={false}
                      beforeUpload={() => false}
                      //   listType="picture-card"
                      // fileList={(formik.getFieldProps("files")?.value as any) || []}
                      fileList={[]}
                      onChange={async (info: any) => {
                        setIsLoadingUploadImage(true);

                        try {
                          const files = info.fileList;
                          const data = new FormData();
                          for (var i = 0; i < files?.length; i++) {
                            data.append("files", files[i]?.originFileObj);
                          }

                          const res = (await uploadFiles(data)) as any;
                          if (res?.success) {
                            setIsLoadingUploadImage(false);
                            const fortmatedData = res?.data?.map(
                              (i: any, index: number) => {
                                return {
                                  type: "image",
                                  url: i?.url,
                                  preview: i?.url,
                                  elements: [],
                                  caption: "Category Image",
                                  mimetype: "image/*",
                                  width: 1080,
                                  height: 1080,
                                  ratio: 1.1,
                                  seq: 0,
                                  ...i,
                                };
                              }
                            );
                            formik.setFieldValue(
                              "avatar",
                              fortmatedData?.[0]?.url
                            );
                          } else {
                            setIsLoadingUploadImage(false);
                          }
                        } catch (error: any) {
                          setIsLoadingUploadImage(false);
                          toast(
                            `${t(
                              "Input buffer contains unsupported image format"
                            )}`,
                            CONFIG_OPTION_TOAST_ERROR
                          );
                        }
                      }}
                      onRemove={() => {
                        //return onGalleryFileRemove();
                      }}
                    >
                      <Button icon={<UploadOutlined />}>
                        {t("Click to Upload")}
                      </Button>
                    </Upload>
                  </div>
                  {formik?.touched?.avatar && formik?.errors?.avatar ? (
                    <div className="text-danger mt-2">
                      {formik?.errors?.avatar}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  {isHavePermissionRole(
                    ROLES_FOR_APP.USER_UPDATE,
                    userPermissions
                  ) && (
                    <button
                      className="btn btn-primary fs-14"
                      color="success"
                      type="submit"
                      disabled={isLoading}
                      style={{ width: "140px" }}
                    >
                      {isLoading ? (
                        <Spinner size="sm" className="me-2"></Spinner>
                      ) : (
                        <>
                          {id
                            ? t("Button Update Artist")
                            : t("Button Create Artist")}
                        </>
                      )}
                    </button>
                  )}
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default ArtistForm;
