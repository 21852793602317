import BreadCrumb from "components/Common/BreadCrumb";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import LabelWrapper from "components/Common/LabelWrapper";
import { useRole } from "components/Hooks/UserHooks";
import CountUp from "react-countup";
import { getPushLogs } from "store/push-notification/thunk";
import CollapseFilter from "components/Common/CollapseFilter";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatus from "components/Common/DropdownStatus";
import {
  COLOR_NOTIFY_TYPE,
  COLOR_OPEN_STATUS,
  COLOR_PUSH_STATUS,
  PUSH_LOG_NOTIFY_TYPE,
  PUSH_LOG_OPEN_STATUS,
  LIST_OPTION_OS,
  PUSH_LOG_PUSH_STATUS,
  getPlatFormImage,
  MANUAL_PUSH_REDIRECT_SCREEN_TARGET,
} from "helpers/constans";
import { formatDateStringToKorea } from "helpers/format";
import { capitalizeFirstLetter } from "utils";
import CollapseContent from "components/Common/CollapseContent";
import userApi from "api/userApi";
import CopyTruncate from "components/Common/CopyTruncate";
import { truncate } from "utils";
import DropdownAsyncUsers from "components/Common/DropdownAsyncUsers";
import TooltipCustom from "components/Common/TooltipCustom";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";

registerLocale("en", en);
registerLocale("ko", ko);

const PushLog = () => {
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();
  const { userPermissions } = useRole();
  const [listUsers, setListUsers] = useState<any>([]);

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    keyword: withDefault(StringParam, ""),
    "sort[createdAt]": withDefault(StringParam, `desc`),
    "condition[userId]": withDefault(StringParam, ""),
    "condition[deviceOs]": withDefault(StringParam, ""),
    "notificationType": withDefault(StringParam, ""),
    "notificationPushType": withDefault(StringParam, ""),
    "condition[status]": withDefault(StringParam, ""),
    "condition[openStatus]": withDefault(StringParam, ''),
    startDate: withDefault(StringParam, ""),
    endDate: withDefault(StringParam, ""),
  });

  const MANUAL_PUSH_REDIRECT_SCREEN_TARGET_LANG =
    MANUAL_PUSH_REDIRECT_SCREEN_TARGET?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const LIST_OPTION_NOTIFY_TYPE_LANG =
    PUSH_LOG_NOTIFY_TYPE?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const LIST_OPTION_PUSH_STATUS_LANG =
    PUSH_LOG_PUSH_STATUS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const LIST_OPTION_OPEN_STATUS_LANG =
    PUSH_LOG_OPEN_STATUS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const LIST_OPTION_OS_LANG =
    LIST_OPTION_OS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [keywordSearch, setKeywordSearch] = useState<string>(
    query?.keyword || ""
  );

  const [userSearch, setUserSearch] = useState<any>(
    listUsers?.filter(
      (item: any) => String(item?.value) === String(query?.["condition[userId]"])
    )[0]
  );

  const [oSSearch, setOSSearch] = useState<Option | null>(
    LIST_OPTION_OS_LANG?.filter(
      (item) => String(item?.value) === String(query?.["condition[deviceOs]"])
    )[0]
  );
  const [dataTypeSearch, setDataTypeSearch] = useState<Option | null>(
    MANUAL_PUSH_REDIRECT_SCREEN_TARGET_LANG?.filter(
      (item) => String(item?.value) === String(query?.["notificationType"])
    )[0]
  );
  const [notifyTypeSearch, setNotifyTypeSearch] = useState<Option | null>(
    LIST_OPTION_NOTIFY_TYPE_LANG?.filter(
      (item) => String(item?.value) === String(query?.["notificationPushType"])
    )[0]
  );
  const [pushStatusSearch, setPushStatusSearch] = useState<Option | null>(
    LIST_OPTION_PUSH_STATUS_LANG?.find(
      (item) => String(item?.value) === String(query?.["condition[status]"])
    ) || null
  );
  const [openStatusSearch, setOpenStatusSearch] = useState<Option | null>(
    LIST_OPTION_OPEN_STATUS_LANG?.filter(
      (item) => (item?.value) === parseInt(query?.["condition[openStatus]"])
    )[0]
  );
  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.startDate
      ? moment(query?.startDate || "", "Y-MM-DD").toDate()
      : null,
    query?.endDate ? moment(query?.endDate || "", "Y-MM-DD").toDate() : null,
  ]);
  const [startDate, endDate] = dateSearch;

  const selectLayoutState = (state: any) => state.PushNotification;
  const PushLogProperties = createSelector(selectLayoutState, (state) => {
    return ({
      pushLogs: state.pushLogs,
      isPushLogLoading: state.isPushLogLoading,
      isPushLogSuccess: state.isPushLogSuccess,

      error: state.error,
    })
  });

  // Inside your component
  const { pushLogs, isPushLogLoading } = useSelector(PushLogProperties);

  function loadTableData() {
    dispatch(getPushLogs(query));
  }

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      keyword: keywordSearch || "",
      "condition[userId]": userSearch?.value || "",
      "condition[deviceOs]": oSSearch?.value || "",
      "notificationType": dataTypeSearch?.value || "",
      "notificationPushType": notifyTypeSearch?.value || "",
      "condition[status]": pushStatusSearch?.value || "",
      "condition[openStatus]": openStatusSearch?.value,
      startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : "",
      endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: +new Date(),
      page: 1,
    });
  };

  const resetData = () => {
    setQuery(
      {
        keyword: "",
        "condition[userId]": "",
        "condition[deviceOs]": "",
        "notificationPushType": "",
        "notificationType": "",
        "condition[status]": "",
        "condition[openStatus]": "",
        startDate: "",
        endDate: "",
        sort_by: "",
        time_request: +new Date(),
        page: 1,
      },
      "push"
    );
    setKeywordSearch((_prev) => "");
    setDateSearch([null, null]);
    setUserSearch((_prev: any) => null);
    setOSSearch((_prev: any) => null);
    setDataTypeSearch((_prev) => null);
    setNotifyTypeSearch((_prev) => null);
    setPushStatusSearch((_prev) => null);
    setOpenStatusSearch((_prev) => null);

  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        thClass: "whitespace-nowrap",
        Cell: ({ ...props }) => {
          return (
            <div>
              {pushLogs?.total -
                ((query?.page - 1) * query?.limit + props?.row?.index)}
            </div>
          );
        },
      },
      {
        Header: t('Notify Type'),
        accessor: "pushType",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: 'text-center whitespace-nowrap',
        Cell: (cell: any) => {
          const status = cell?.row?.original?.notification?.pushType;
          return (
            <div className="text-center" style={{ minWidth: '80px' }}>
              <span className={`rounded-pill badge bg-${COLOR_NOTIFY_TYPE[status] || 'secondary'}`}>{LIST_OPTION_NOTIFY_TYPE_LANG?.find((item: any) => String(item?.value) === String(status))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t('Push Status'),
        accessor: "PushStatus",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: 'text-center whitespace-nowrap',
        Cell: (cell: any) => {
          const status = cell?.row?.original?.status;
          const detailError = cell?.row?.original?.detailError;
          const msgError = i18n?.language === "en" ? detailError?.en : detailError?.ko;
          const msgDeactivated = cell?.row?.original?.statusReason;
          const minWidth: string | number = i18n?.language === "en" ? "125px" : "96px";

          return (
            <div className="position-relative" style={{ minWidth: minWidth }}>
              <div className="text-center" style={{ lineHeight: "16px" }}>
                <span className={`rounded-pill badge bg-${COLOR_PUSH_STATUS[status] || 'secondary'}`}>{t(PUSH_LOG_PUSH_STATUS?.find((item: any) => String(item?.value) === String(status))?.label ?? '')}</span>
              </div>
              {
                (status === "failed" && msgError) && <TooltipCustom
                  title={t(msgError)}
                  className="d-inline-block vertical-align-middle ms-1 thumbnail-mt"
                  style={{ transform: 'translateY(2px)', position: "absolute", top: -3, right: 0, paddingLeft: 4, paddingRight: 3 }}
                >
                  <i className="ri-question-line align-bottom text-secondary" style={{ fontSize: "16px" }} ></i>
                </TooltipCustom>
              }
              {/* {
                (status === "deactivated" && msgDeactivated) && <TooltipCustom
                  title={t(msgDeactivated)}
                  className="d-inline-block vertical-align-middle ms-1 thumbnail-mt"
                  style={{ transform: 'translateY(2px)', position: "absolute", top: -3, right: 0, paddingLeft: 4, paddingRight: 3 }}
                >
                  <i className="ri-question-line align-bottom text-secondary" style={{ fontSize: "16px" }} ></i>
                </TooltipCustom>
              } */}
            </div>
          )
        },
      },
      {
        Header: t('Open Status'),
        accessor: "openStatus",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: 'text-center whitespace-nowrap',
        Cell: (cell: any) => {
          const status = cell?.value;
          return (
            <div className="text-center" style={{ minWidth: '80px' }}>
              <span className={`rounded-pill badge bg-${COLOR_OPEN_STATUS[status] || 'secondary'}`}>{LIST_OPTION_OPEN_STATUS_LANG?.find((item: any) => (item?.value) === (status))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t("Nickname"),
        accessor: "nickname",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          let items = cell?.row?.original?.user;

          return (
            <>
              <div className="cursor-pointer">{items?.nickname ? items?.nickname : '-'}</div>
            </>
          );
        },
      },
      {
        Header: t("Email"),
        accessor: "email",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          let items = cell?.row?.original?.user;
          return (
            <>
              <div className="cursor-pointer">{items?.email}</div>
            </>
          );
        },
      },
      {
        Header: t("Platform"),
        accessor: "providers_platform",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          let items = cell?.row?.original?.user?.providers || [];

          if (items.length > 1) {
            items = items.filter((item: any) => item?.type !== "default");
          }

          return (
            items?.length > 0 && (
              <div className="d-flex flex-column gap-1">
                {items?.map((item: any, index: number) => {
                  const type = item?.type;
                  let imgPlatform = getPlatFormImage(type);
                  return (
                    <div
                      key={index}
                      className="cursor-pointer d-flex gap-2 align-items-center whitespace-nowrap"
                    >
                      <div style={{ width: "25px" }}>
                        {imgPlatform && (
                          <img
                            src={imgPlatform}
                            width={25}
                            height={25}
                            alt="platform"
                          />
                        )}
                      </div>
                      <div className="cursor-pointer">
                        {t(
                          capitalizeFirstLetter(
                            type == "default" ? "Email" : type
                          )
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          );
        },
      },
      {
        Header: t("deviceOS"),
        accessor: "deviceOs",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const deviceOS = cell?.value;
          return (
            deviceOS && (
              <div className="cursor-pointer" style={{ width: 80 }}>
                {t(`${deviceOS}`)}
              </div>
            )
          );
        },
      },
      {
        Header: t("FCM Token"),
        accessor: "token",
        thClass: "whitespace-nowrap",
        filterable: true,
        sortable: false,
        thWidth: 320,
        Cell: (cell: any) => {
          const token = cell?.value;

          return token && <div className="cursor-pointer" style={{ maxWidth: "320px" }}>
            <CopyTruncate
              contentCopy={token}
              style={{ minWidth: "70px" }}
            >
              {truncate(token, 70)}
            </CopyTruncate>
          </div>;
        },
      },
      {
        Header: t("Title"),
        accessor: "title",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const title = cell?.value;

          return (
            <>
              <CollapseContent
                style={{ minWidth: '150px' }}
                dataOriginal={title}
                isRank={false}
                isInlineBlock={true}
                isShowModal={true}
                titleModal={t('Title')} />
            </>
          );
        },
      },
      {
        Header: t("Content"),
        accessor: "description",
        filterable: true,
        sortable: false,
        thWidth: 220,
        Cell: (cell: any) => {
          const description = cell?.value;

          return (
            <>
              <CollapseContent
                style={{ minWidth: '220px' }}
                dataOriginal={description}
                isRank={false}
                isInlineBlock={true}
                isShowModal={true}
                titleModal={t('Content')} />
            </>
          );
        },
      },
      {
        Header: t('Data Type'),
        accessor: "DataType",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const status = cell?.row?.original?.notification?.type;
          return (
            <div className="text-center" style={{ minWidth: '80px' }}>
              <span>{MANUAL_PUSH_REDIRECT_SCREEN_TARGET_LANG?.find((item: any) => String(item?.value) === String(status))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t("Created at"),
        accessor: "createdAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end whitespace-nowrap",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value);
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updatedAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end whitespace-nowrap",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value);

          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, userPermissions, pushLogs?.total]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const handleSelectAsyncUsers = (event: any) => {
    setUserSearch(event)
  }



  useEffect(() => {
    document.title = `${t("Push Log")} - ${t("Push Notification")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        const [resUsers]: any = await Promise.all([
          userApi.users({ page: 1, limit: 20000 })
        ]);

        let dataUsersOption: any = [];
        if (resUsers?.data?.items) {
          dataUsersOption = resUsers?.data?.items?.map((a: any) => ({
            value: String(a?._id),
            label: (
              <div className="d-flex align-items-center gap-2">
                {`${a?.nickname} - ${a?.email}`}
              </div>
            ),
            data: a,
          })) as Option[]
        }
        setListUsers((_prev: any) => dataUsersOption)
      } catch (error: any) {
        return error;
      }
    };

    handleCallAllOption();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("Push Log")}
            pageTitle={t("Home")}
          />
          <Row>
            <Col lg={12}>
              <Card
                id="customerList"
                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
              >
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t("Total")}:</span>
                          <CountUp
                            start={0}
                            end={pushLogs?.total || 0}
                            duration={1}
                            className="text-primary"
                          />
                        </h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                  <CollapseFilter>
                    <Row className="g-4 align-items-center mt-0 mt-md-2">
                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper
                          label={t("Title, Content")}
                          isShow={!!keywordSearch}
                        >
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Title, Content")}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <div>
                          <LabelWrapper label={t("User")} isShow={true}>
                            <DropdownAsyncUsers
                              name="manual-push-users"
                              onChangeSelect={handleSelectAsyncUsers}
                              isMulti={false}
                              placeholder={`${t("Select User")}...`}
                              isShowIconCheck={false}
                              listCheckedUsers={userSearch}
                            />
                          </LabelWrapper>
                        </div>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-3"
                      >
                        <LabelWrapper label={t("OS")} isShow={true}>
                          <DropdownOption
                            name="os"
                            dataList={LIST_OPTION_OS_LANG || []}
                            placeholder={`${t("OS")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={oSSearch || null}
                            onChangeSelect={(e: any) => setOSSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-3"
                      >
                        <div>
                          <LabelWrapper label={t("Data Type")} isShow={true}>
                            <DropdownOption
                              name="role"
                              dataList={MANUAL_PUSH_REDIRECT_SCREEN_TARGET_LANG || []}
                              placeholder={`${t("Data Type")}...`}
                              className="dropdown-status-rounded"
                              classNamePrefix="name-prefix"
                              initialValue={dataTypeSearch || null}
                              onChangeSelect={(e: any) => setDataTypeSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t("All Filter"), value: "" }}
                            />
                          </LabelWrapper>
                        </div>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-3"
                      >
                        <div>
                          <LabelWrapper label={t("Notify Type")} isShow={true}>
                            <DropdownStatus
                              name="nofify-type"
                              dataList={LIST_OPTION_NOTIFY_TYPE_LANG || []}
                              placeholder={`${t("Notify Type")}...`}
                              className="dropdown-status-rounded"
                              classNamePrefix="name-prefix"
                              initialValue={notifyTypeSearch || null}
                              onChangeSelect={(e: any) => setNotifyTypeSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t("All Filter"), value: "" }}
                              colors={COLOR_NOTIFY_TYPE}
                            />
                          </LabelWrapper>
                        </div>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-3"
                      >
                        <LabelWrapper label={t("Push Status")} isShow={true}>
                          <DropdownStatus
                            name="push-status"
                            dataList={LIST_OPTION_PUSH_STATUS_LANG || []}
                            placeholder={`${t("Push Status")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={pushStatusSearch || null}
                            onChangeSelect={(e: any) => setPushStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                            colors={COLOR_PUSH_STATUS}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-3"
                      >
                        <LabelWrapper label={t("Open Status")} isShow={true}>
                          <DropdownStatus
                            name="open-status"
                            dataList={LIST_OPTION_OPEN_STATUS_LANG || []}
                            placeholder={`${t("Open Status")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={openStatusSearch || null}
                            onChangeSelect={(e: any) => setOpenStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                            colors={COLOR_OPEN_STATUS}
                            isIntegerValue={true}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-3 date-picker-wrapper-custom"
                      >
                        <LabelWrapper
                          label={t("Date")}
                          isShow={!!startDate || !!endDate}
                        >
                          <DatePickerCustom
                            startDate={startDate || null}
                            endDate={endDate || null}
                            onChangePicker={handleChangePicker}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={12}
                        lg={12}
                        className="hstack gap-1 justify-content-center justify-content-md-end mt-sm-4 mt-md-3"
                      >
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t("Button Search")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t("Button Reset")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CollapseFilter>
                </div>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={pushLogs?.items?.length ? pushLogs?.items : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={pushLogs?.total}
                      customPageCount={Math.ceil(
                        Number(pushLogs?.total) / Number(query.limit)
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isPushLogLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PushLog;
