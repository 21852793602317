import { Option } from "api/types/_public";
import imgEmail from "../assets/images/platform/mail.webp";
import imgGoogle from "../assets/images/platform/google.svg";
import imgKakao from "../assets/images/platform/kakao.svg";
import imgNaver from "../assets/images/platform/naver.svg";
import imgApple from "../assets/images/platform/apple.svg";
import imgAmazon from "../assets/images/platform/amazon.svg";
import imgFirebase from "../assets/images/platform/firebase.svg";
import imgFacebook from "../assets/images/platform/facebook.svg";

export const SCHEDULE_OPTIONS: Option[] = [
  {
    label: "Now",
    value: "now",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  // {
  //   label: 'Except Holiday In Korea',
  //   value: 'except_holiday_in_Korea',
  // }
];

export const SCHEDULE_MONTHLY_OPTIONS: Option[] = Array.from(
  { length: 31 },
  (_, index) => {
    const day = (index + 1).toString().padStart(2, "0");
    return { label: `${day}th`, value: day };
  }
);

export const SCHEDULE_WEEKLY_OPTIONS: Option[] = [
  {
    label: "Monday",
    value: "1",
  },
  {
    label: "Tuesday",
    value: "2",
  },
  {
    label: "Wednesday",
    value: "3",
  },
  {
    label: "Thursday",
    value: "4",
  },
  {
    label: "Friday",
    value: "5",
  },
  {
    label: "Saturday",
    value: "6",
  },
  {
    label: "Sunday",
    value: "7",
  },
];

export const SCHEDULE_CAMPAIGN_STATISTIC_OPTIONS: Option[] = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const ACTION_TYPE_CAMPAIGN = {
  SAVE: "save",
  POST: "post",
};

export const METHOD_OPTIONS: Option[] = [
  {
    label: "GET",
    value: "GET",
  },
  {
    label: "POST",
    value: "POST",
  },
  {
    label: "PUT",
    value: "PUT",
  },
  {
    label: "DELETE",
    value: "DELETE",
  },
];

export const BROWSER_OPTIONS: Option[] = [
  {
    label: "Browser_Chrome",
    value: "Chrome",
  },
  {
    label: "Browser_Safari",
    value: "Safari",
  },
  {
    label: "Browser_Unknown_Browser",
    value: "Unknown Browser",
  },
];

export const OS_OPTIONS: Option[] = [
  {
    label: "OS_iOS",
    value: "iOS",
  },
  {
    label: "OS_Mac",
    value: "Mac",
  },
  {
    label: "OS_Windows",
    value: "Windows",
  },
  {
    label: "OS_Android",
    value: "Android",
  },
  {
    label: "OS_Etc",
    value: "Etc",
  },
];

export const IS_OPTIONS: Option[] = [
  {
    label: "YES",
    value: "1",
  },
  {
    label: "NO",
    value: "0",
  },
];

export const TYPE_SHOW_MORE_HASHTAG = {
  SCROLL: "scroll",
  MODAL: "modal",
};

export const ARR_INDEX_TEXT_DAY_OF_WEEK: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

//dispatch

export const TYPE_POST: Option[] = [
  {
    label: "Feed",
    value: "feed",
  },
  {
    label: "Card",
    value: "card",
  },
];

// Feed
export const ENUM_STATUS_FEED = {
  IN_REVIEW: "in_review",
  ACTIVATED: "activated",
  DISABLED: "disabled",
};

export const COLOR_STATUS_FEED: any = {
  [ENUM_STATUS_FEED.IN_REVIEW]: "primary",
  [ENUM_STATUS_FEED.ACTIVATED]: "success",
  [ENUM_STATUS_FEED.DISABLED]: "danger",
};

export const STATUS_FEED_OPTION = [
  {
    label: "In Review",
    value: ENUM_STATUS_FEED.IN_REVIEW,
  },
  {
    label: "Activated",
    value: ENUM_STATUS_FEED.ACTIVATED,
  },
  {
    label: "Disabled",
    value: ENUM_STATUS_FEED.DISABLED,
  },
];

// Snap
export const ENUM_STATUS_SNIPPET = {
  ACTIVATED: "activated",
  DISABLED: "disabled",
};

export const COLOR_STATUS_SNIPPET: any = {
  [ENUM_STATUS_FEED.IN_REVIEW]: "primary",
  [ENUM_STATUS_FEED.ACTIVATED]: "success",
  [ENUM_STATUS_FEED.DISABLED]: "danger",
};

export const STATUS_SNIPPET_OPTION = [
  {
    label: "In Review",
    value: ENUM_STATUS_FEED.IN_REVIEW,
  },
  {
    label: "Activated",
    value: ENUM_STATUS_SNIPPET.ACTIVATED,
  },
  {
    label: "Disabled",
    value: ENUM_STATUS_SNIPPET.DISABLED,
  },
];

// Artist
export const ENUM_TYPE_ARTIST = {
  DEFAULT: "default",
  IDOL: "idol",
  ACTOR: "actor",
  ENTERTAINMENT: "entertainment",
};

export const COLOR_TYPE_ARTIST: any = {
  [ENUM_TYPE_ARTIST.DEFAULT]: "warning",
  [ENUM_TYPE_ARTIST.IDOL]: "primary",
  [ENUM_TYPE_ARTIST.ACTOR]: "success",
  [ENUM_TYPE_ARTIST.ENTERTAINMENT]: "danger",
};

export const TYPE_ARTIST_OPTION = [
  {
    label: "Default",
    value: ENUM_TYPE_ARTIST.DEFAULT,
  },
  {
    label: "Idol",
    value: ENUM_TYPE_ARTIST.IDOL,
  },
  {
    label: "Actor",
    value: ENUM_TYPE_ARTIST.ACTOR,
  },
  {
    label: "Entertainment",
    value: ENUM_TYPE_ARTIST.ENTERTAINMENT,
  },
];

export const COLOR_TYPE_ARTIST_TDI: any = {
  1: "warning",
  2: "primary",
  3: "success",
  4: "danger",
};

export const TYPE_GROUP_TDI_OPTION = [
  {
    label: "Default",
    value: 1,
  },
  {
    label: "GROUP_TDI_Idol",
    value: 2,
  },
  {
    label: "Actor",
    value: 3,
  },
  {
    label: "Entertainment",
    value: 4,
  },
];

export const TYPE_ARTIST_TDI_OPTION = [
  {
    label: "Default",
    value: 1,
  },
  {
    label: "ARTIST_TDI_Idol",
    value: 2,
  },
  {
    label: "Actor",
    value: 3,
  },
  {
    label: "Entertainment",
    value: 4,
  },
];

export const ENUM_STATUS_ARTIST = {
  ACTIVATED: "activated",
  DISABLED: "disabled",
};

export const COLOR_STATUS_ARTIST: any = {
  [ENUM_STATUS_ARTIST.ACTIVATED]: "success",
  [ENUM_STATUS_ARTIST.DISABLED]: "danger",
};

export const COLOR_GENDER: any = {
  unknown: "warning",
  male: "success",
  female: "danger",
};

export const STATUS_ARTIST_OPTION = [
  {
    label: "Activated",
    value: ENUM_STATUS_ARTIST.ACTIVATED,
  },
  {
    label: "Disabled",
    value: ENUM_STATUS_ARTIST.DISABLED,
  },
];

// User
export const ENUM_STATUS_USER = {
  PENDING: "pending",
  ACTIVATED: "activated",
  DISABLED: "disabled",
};

export const COLOR_STATUS_USER: any = {
  [ENUM_STATUS_USER.PENDING]: "primary",
  [ENUM_STATUS_USER.ACTIVATED]: "success",
  [ENUM_STATUS_USER.DISABLED]: "danger",
};

export const STATUS_USER_OPTION = [
  {
    label: "Pending",
    value: ENUM_STATUS_USER.PENDING,
  },
  {
    label: "Activated",
    value: ENUM_STATUS_USER.ACTIVATED,
  },
  {
    label: "Disabled",
    value: ENUM_STATUS_USER.DISABLED,
  },
];

//Advertisement

export const ENUM_STATUS_ADVERTISEMENT = {
  ACTIVATED: "true",
  DISABLED: "false",
};

export const COLOR_STATUS_ADVERTISEMENT: any = {
  [ENUM_STATUS_ADVERTISEMENT.ACTIVATED]: "success",
  [ENUM_STATUS_ADVERTISEMENT.DISABLED]: "danger",
};

export const STATUS_ADVERTISEMENT_OPTION = [
  {
    label: "Activated",
    value: ENUM_STATUS_ADVERTISEMENT.ACTIVATED,
  },
  {
    label: "Disabled",
    value: ENUM_STATUS_ADVERTISEMENT.DISABLED,
  },
];

export const ENUM_POSITION_ADVERTISEMENT = {
  HOT: "hot",
  NEWS: "news",
  WALLPAPER_EDIT: "wallpaper_edit",
  VIDEO: "video",
  FEED: "feed",
};

export const COLOR_POSITION_ADVERTISEMENT: any = {
  [ENUM_POSITION_ADVERTISEMENT.HOT]: "warning",
  [ENUM_POSITION_ADVERTISEMENT.NEWS]: "primary",
  [ENUM_POSITION_ADVERTISEMENT.WALLPAPER_EDIT]: "info",
  [ENUM_POSITION_ADVERTISEMENT.VIDEO]: "danger",
  [ENUM_POSITION_ADVERTISEMENT.FEED]: "success",
};

export const POSITION_ADVERTISEMENT_OPTION = [
  {
    label: "Hot",
    value: ENUM_POSITION_ADVERTISEMENT.HOT,
  },
  {
    label: "News",
    value: ENUM_POSITION_ADVERTISEMENT.NEWS,
  },
  {
    label: "Wallpaper Edit",
    value: ENUM_POSITION_ADVERTISEMENT.WALLPAPER_EDIT,
  },
  {
    label: "Video",
    value: ENUM_POSITION_ADVERTISEMENT.VIDEO,
  },
  {
    label: "Feed",
    value: ENUM_POSITION_ADVERTISEMENT.FEED,
  },
];

export const STATUS_USED = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Used",
    value: "used",
  },
  {
    label: "Unused",
    value: "unused",
  },
];

export const COLOR_STATUS_USED: any = {
  used: "primary",
  unused: "success",
};

export const LIST_OPTION_GENDER = [
  {
    label: "Gender_Unknown",
    value: "unknown",
  },
  {
    label: "Gender_Male",
    value: "male",
  },
  {
    label: "Gender_Female",
    value: "female",
  },
];

export const LIST_OPTION_PLATFORM = [
  {
    label: "Platform_Email",
    value: "default",
  },
  {
    label: "Platform_Kakao",
    value: "kakao",
  },
  {
    label: "Platform_Naver",
    value: "naver",
  },
  {
    label: "Platform_Google",
    value: "google",
  },
  {
    label: "Platform_Apple",
    value: "apple",
  },
  {
    label: "Platform_Apple_Facebook",
    value: "facebook",
  },
  {
    label: "Amazon",
    value: "amazon",
  },
  {
    label: "Platform_Apple_Firebase",
    value: "firebase",
  },
];

export const LIST_OPTION_OS = [
  {
    label: "iOS",
    value: "ios",
  },
  {
    label: "Android",
    value: "android",
  },
];

export const getPlatFormImage = (type: string) => {
  switch (type) {
    case "default":
      return imgEmail;
    case "google":
      return imgGoogle;
    case "naver":
      return imgNaver;
    case "kakao":
      return imgKakao;
    case "apple":
      return imgApple;
    case "amazon":
      return imgAmazon;
    case "firebase":
      return imgFirebase;
    case "facebook":
      return imgFacebook;
    default:
      return "";
  }
};

export const STATUS_USER_FEEDBACK_OPTION = [
  {
    label: "Received",
    value: "received",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "In Progress",
    value: "in_progress",
  },
  {
    label: "Completed",
    value: "completed",
  },
];

export const TYPE_USER_FEEDBACK_OPTION_CONSTANT = [
  {
    label: "Suggestion",
    value: "suggestion",
  },
  {
    label: "Bug Report",
    value: "bug_report",
  },
  {
    label: "Feature Request",
    value: "feature_request",
  },
  {
    label: "Complaint",
    value: "complaint",
  },
  {
    label: "Inquiry",
    value: "inquiry",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const LIST_OPTION_ROLE = [
  {
    value: "normal",
    label: "Normal",
  },
  {
    value: "premium",
    label: "Premium",
  },
  {
    value: "guest",
    label: "Guest",
  },
];

export const ENUM_TYPE_ADVERTISEMENT = {
  NATIVE: "native",
  VIDEO: "video",
};

export const TYPE_ADVERTISEMENT_OPTION = [
  {
    label: "AD_Native",
    value: ENUM_TYPE_ADVERTISEMENT.NATIVE,
  },
  {
    label: "AD_Video",
    value: ENUM_TYPE_ADVERTISEMENT.VIDEO,
  },
];

export const COLOR_TYPE_ADVERTISEMENT: any = {
  [ENUM_TYPE_ADVERTISEMENT.NATIVE]: "primary",
  [ENUM_TYPE_ADVERTISEMENT.VIDEO]: "danger",
};

export const LIST_OPTION_TYPE = [
  {
    label: "Role_Default",
    value: "default",
  },
  {
    label: "Role_Idol",
    value: "idol",
  },
  {
    label: "Role_Actor",
    value: "actor",
  },
  {
    label: "Role_Entertainment",
    value: "entertainment",
  },
];

export const POSITION_DESCRIPTION_ADVERTISEMENT_OPTION = [
  {
    label: "Bottom",
    value: ENUM_POSITION_ADVERTISEMENT.HOT,
  },
  {
    label: "Feed (Hot)",
    value: ENUM_POSITION_ADVERTISEMENT.NEWS,
  },
  {
    label: "Wallpaper",
    value: ENUM_POSITION_ADVERTISEMENT.WALLPAPER_EDIT,
  },
  {
    label: "Snap",
    value: ENUM_POSITION_ADVERTISEMENT.VIDEO,
  },
  {
    label: "Photo News",
    value: ENUM_POSITION_ADVERTISEMENT.FEED,
  },
];

//notification setting
export const ENUM_NOTI_SETTING_STATUS = {
  ACTIVATED: "activated",
  DISABLED: "disabled",
};

export const NOTI_SETTING_STATUS_OPTION = [
  { label: "Activated", value: ENUM_NOTI_SETTING_STATUS.ACTIVATED },
  { label: "Disabled", value: ENUM_NOTI_SETTING_STATUS.DISABLED },
];

export const ENUM_NOTI_SETTING_TYPE = {
  HOT_FEED: "hot_feed",
  WALLPAPER: "wallpaper",
  SNAP: "snap",
  PHOTO_NEWS: "photo_news",
  EVENT_AND_PROMOTION: "event_and_promotion",
};

export const NOTI_SETTING_TYPE_OPTION = [
  { label: "Hot Feed", value: ENUM_NOTI_SETTING_TYPE.HOT_FEED },
  { label: "Wallpaper", value: ENUM_NOTI_SETTING_TYPE.WALLPAPER },
  { label: "Snap", value: ENUM_NOTI_SETTING_TYPE.SNAP },
  { label: "Photo News", value: ENUM_NOTI_SETTING_TYPE.PHOTO_NEWS },
  {
    label: "Event And Promotion",
    value: ENUM_NOTI_SETTING_TYPE.EVENT_AND_PROMOTION,
  },
];

export const NOTI_SETTING_TYPE_COLOR: any = {
  [ENUM_NOTI_SETTING_TYPE.HOT_FEED]: "warning",
  [ENUM_NOTI_SETTING_TYPE.WALLPAPER]: "primary",
  [ENUM_NOTI_SETTING_TYPE.SNAP]: "info",
  [ENUM_NOTI_SETTING_TYPE.PHOTO_NEWS]: "danger",
  [ENUM_NOTI_SETTING_TYPE.EVENT_AND_PROMOTION]: "success",
};

export const ENUM_NOTIFY_TYPE = {
  AUTO: "auto",
  MANUAL: "manual",
};

export const COLOR_NOTIFY_TYPE: any = {
  [ENUM_NOTIFY_TYPE.AUTO]: "primary",
  [ENUM_NOTIFY_TYPE.MANUAL]: "orange",
};

export const PUSH_LOG_NOTIFY_TYPE = [
  {
    value: ENUM_NOTIFY_TYPE.AUTO,
    label: "Auto",
  },
  {
    value: ENUM_NOTIFY_TYPE.MANUAL,
    label: "Manual",
  },
];

export const ENUM_PUSH_LOG_PUSH_STATUS = {
  SENT: "sent",
  FAILED: "failed",
  DEACTIVATED: "deactivated"
};

export const COLOR_PUSH_STATUS: any = {
  [ENUM_PUSH_LOG_PUSH_STATUS.FAILED]: "danger",
  [ENUM_PUSH_LOG_PUSH_STATUS.SENT]: "success",
  [ENUM_PUSH_LOG_PUSH_STATUS.DEACTIVATED]: "orange-bold",
};

export const PUSH_LOG_PUSH_STATUS = [
  {
    value: ENUM_PUSH_LOG_PUSH_STATUS.SENT,
    label: "Push Log Sent",
  },
  {
    value: ENUM_PUSH_LOG_PUSH_STATUS.FAILED,
    label: "Push Log Failed",
  },
  {
    value: ENUM_PUSH_LOG_PUSH_STATUS.DEACTIVATED,
    label: "Push Log Deactivated",
  },
];

export const ENUM_PUSH_LOG_OPEN_STATUS = {
  OPEN: 1,
  NOTOPEN: 0,
};

export const COLOR_OPEN_STATUS: any = {
  [ENUM_PUSH_LOG_OPEN_STATUS.OPEN]: "primary",
  [ENUM_PUSH_LOG_OPEN_STATUS.NOTOPEN]: "orange-light",
};

export const PUSH_LOG_OPEN_STATUS = [
  {
    value: ENUM_PUSH_LOG_OPEN_STATUS.OPEN,
    label: "Open",
  },
  {
    value: ENUM_PUSH_LOG_OPEN_STATUS.NOTOPEN,
    label: "Not-Open",
  },
];

export const MANUAL_PUSH_REDIRECT_SCREEN_TARGET = [
  {
    label: "Open App",
    value: "default",
  },
  {
    label: "Feed",
    value: "hot_feed",
  },
  {
    label: "Wallpaper",
    value: "wallpaper",
  },
  {
    label: "Snap",
    value: "snap",
  },
  {
    label: "Photo News",
    value: "photo_news",
  },
  {
    label: "Promotion",
    value: "event_and_promotion",
  },
  {
    label: "Open Deep Link",
    value: "open_link",
  },
];
